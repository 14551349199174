import React from 'react';
import styled from 'styled-components';
import ConditionalLayout from '../components/ConditionalLayout';

const Triangle1 = styled.h2`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  border-bottom: 50vh solid #99eaff;
  bottom: 0;
  border-right: 50vw solid transparent;
  border-bottom-width: 20vh;
  border-right-width: 50vw;
`;
const Triangle2 = styled.h2`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  border-top: 20vh solid #00aa00;
  border-left: 75vw solid transparent;
  right: 0;
  border-top-width: 40vh;
  border-left-width: 70vw;
  margin-right: 20px;
`;

const Vehicle = () => (
  <ConditionalLayout>
    <Triangle2 />
    <h1>保有車両</h1>
    <p>１０t大型車２台（低床ウイング）</p>
    <p>４t車６台（ウイング）</p>
    <p>２t車１４台（ショート箱車・ロング箱車・ゲート車）</p>
    <p>ハイエース１台</p>
    <Triangle1 />
  </ConditionalLayout>
);

export default Vehicle;
